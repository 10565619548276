*{
    border:0px;
    padding:0px;
    margin:0px;
}

html{
    background-color: #f9cfff;
    background-image: url("https://cdn.discordapp.com/attachments/912314291066118164/1009218620716503200/IMG_3017.png"); 
    /* https://cdn.discordapp.com/attachments/912314291066118164/1008849445699465216/IMG_3017.png */
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: hidden;

    background-size:cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    height:100vh;
    width:auto;
}

body{
    height:100vh;
    display:flex;
    align-items: stretch;

    flex-direction: column;
    background-size:cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
}

#root{
    height:100vh;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}

.mint{
    height: auto;
    max-height: 40vh;
    max-width:auto;

    /* aspect-ratio: 1.34; */

}
.mintbtn{
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 80vw;
    /* position:absolute;
    top:100; */
    /* left:50vh; */
}

.sketch{
    width: 100vw;
    height:100vh;
    z-index: -1;
    position:absolute;
    top:0;
    left:0;

}

.ctraddr{

    /* padding:100px; */
    /* position:absolute;
    bottom:0;
    left:0; */
    text-align: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 2em;
    color:#adb9ff;
    width: fit-content;
}
.ctrdaddy{
    display: flex;
    text-align: center;
    justify-content: space-around;
    

}

.spesh{
    color:#adb9ff
}
/* 
svg{
    font   : bold 70px Century Gothic, Arial;
    width  : 100%;
    height : 120px;
  }
  
  text{
    fill            : none;
    stroke          : black;
    stroke-width    : .5px;
    stroke-linejoin : round;
    animation       : 2s pulsate infinite;
  }
  
  @keyframes pulsate {
    50%{ stroke-width:5px }
  } */